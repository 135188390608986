import { Box, Typography } from "@mui/material"
import { grey } from "@mui/material/colors"

function NotFound() {
    return <Box sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%'
    }}>
        <Typography variant="body1" fontWeight={'100'} sx={{
            color: grey['600'],
            fontFamily: 'Chivo Mono',
            letterSpacing: '2px'
        }}>
            404 | Not Found
        </Typography>
    </Box>
}

export default NotFound