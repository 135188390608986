import { Typography } from "@mui/material";

function ServicesCard({ name, icon, img }) {
  return (
    <div
      className="service-wrapper"
      style={{
        flexGrow: 1,
        borderRadius: "10px",
        backgroundColor: "black",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div
        className="service-wrapperrr"
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: 10,
          background:
            "linear-gradient(to right, rgb(24, 24, 24) 35%, rgba(160, 160, 160, 0) 100%)",
          padding: "30px",
        }}
      >
        {icon}
        <Typography
          mt={2}
          variant="h5"
          color={"white"}
          fontWeight={300}
          letterSpacing={"1px"}
          fontFamily={"Chivo Mono"}
          maxWidth={'80%'}
        >
          {name}
        </Typography>
      </div>
      <img
        className="service-image"
        src={img}
        style={{
          float: "right",
          height: 250,
          width: "100%",
          objectFit: "cover",
          scale: 2,
        }}
      />
    </div>
  );
}

export default ServicesCard;
