import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import HomePage from './Pages/HomePage/HomePage';
import AboutUs from './Pages/AboutUs/AboutUs';
import ContactUs from './Pages/ContactUs/ContactUs';
import TrackingPage from './Pages/TrackingPage/TrackingPage';
import NotFound from './Pages/NotFound';
import ContactForm from './Pages/ContactForm/ContactForm';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/track" element={<TrackingPage />} />
                <Route path="/message" element={<ContactForm />} />
                <Route path="/notfound" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
