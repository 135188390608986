const BASE_URL = "/ups";
const USERNAME = "yannick221"
const PASSWORD = "Mireille12348#"
const CLIENT_ID = "4AHEheLXAMRMtKkj9H8k3pGlhn5ICcsZArDOqtewum3IFSlv"
const CLIENT_SECRET = "8ksyotPSLvlmyWKZrk6x0T9SD9VOqaWXAXjtGdJAoo2RYSFmoUHATsWZFEPzobCz"

export const tracking = async (inquiryNumber) => {
    const query = new URLSearchParams({
        locale: 'en_US',
        returnSignature: 'false'
    }).toString();

    const resp = await fetch(
        `https://advanztektesting.tech/api/ups/track/${inquiryNumber}`,
        {
            method: 'GET',
        }
    );
    const data = await resp.json();
    return data
}

export const authClient = async () => {
    const url = 'https://advanztektesting.tech/api/ups/get/token';

    const resp = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: 'Bearer eyJraWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzM4NCJ9.eyJzdWIiOiJ5YW5uaWNrb25kaWFzQGhvdG1haWwuY29tIiwiY2xpZW50aWQiOiI0QUhFaGVMWEFNUk10S2tqOUg4azNwR2xobjVJQ2NzWkFyRE9xdGV3dW0zSUZTbHYiLCJtZXJfaWQiOiJHM1g0MDciLCJpc3MiOiJodHRwczovL2FwaXMudXBzLmNvbSIsInV1aWQiOiI5MEM1QjAxNS04MkM2LTFFNDQtQUFERS1CNTAyREVENzZBMDUiLCJzaWQiOiI2NGM0YjYyMC0yZmFhLTQzNTYtYjA0MS1mM2EwZjM2Y2MxZmEiLCJhdWQiOiJPZGltb3RvcnMgdGVzdGluZyIsImF0IjoibEFLRVZOQkNFZEhoeDZDZFFRTnFKR1lqQlFGZiIsIm5iZiI6MTcwMTQxOTczMCwiRGlzcGxheU5hbWUiOiJPZGltb3RvcnMgdGVzdGluZyIsImV4cCI6MTcwMTQzNDEzMCwiaWF0IjoxNzAxNDE5NzMwLCJqdGkiOiJmZWFkYjNmOS0xNTM1LTRkN2UtYWZmOC1hMjczMjMwMWM3MzAifQ.ftNbXpjYhj1rhxwSunpetDr6UoKKFvvE7ppjYqxMWGKtkCCoo-QF0Wf4mhH3Nqo-DwJNSBLuDIVgJr9ZYfgoS9fYHXEjxsPhriBkmsCOKnsqFUz4n3WVZ5HbrIBCWFNCJAOsJE_jSATWbJ7-c4XUVhKIBlaTOR_Zg8u0XWu-s68K5DrRG6XP5RfvQoHqf_bTUpYXNF3NHMxBESYx9nm_yz7tu4YgenZREzO1n4m321XFfQnVE89XHI0riM5RfkvNGkwmRgUarRUpDH1MQ7P_sXO8VBEs-qCo_BTO7TcmbSzi5oFzdfbmztmwqd1JQ0wtPf5k_9oeDpcRGHHoiTuAIRz4eBfaf70bKCGOJEI7DEER6RLcxRwDZpVDl0y4kETJEVQQQXUONytLd0XCyD1MK7PpaIkcw3OquET5IRp5vcgUqR9h5P5kFUeYXp__5F-gIDaC8AbyLWWgGsaGTessZBO4XxUKvrT92CAmch3VsEMhe-8apdkPlg54K2sZCD-6nkNIn0prAcXhq16C0Qnp7CEjVHEawbq-ogIZ2JTOtD-D9hI2-Co0a4i97FkPxqHdQkEoRPh3za_GhPRPIbdIvrJrDeaJnXD_YsLVGQbQn73B6ahhdo9ZjI2SQBg6yeKE5doqPMij5ezAYC6jPmw87WQbkIWuVEcgueeF9MqfPxA'
            }
        }
    );

    const data = await resp.json();
    return data
}
