import { Box, Grid, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';

const wrapperStyles = { display: 'flex', flexDirection: 'column', gap: '1em' };
const typoPrimary = { color: 'white', margin: 0, padding: 0, fontWeight: 300, width: '60%' };
const typoSecondary = { color: grey[600], margin: 0, padding: 0, fontWeight: 300 };

function FooterAboutInfo({ footerAboutInfo }) {
    const renderedResult = footerAboutInfo.map((col, index) =>
        <Grid container key={index}>
            <Grid item xs={1}>
                <Box>{col.icon}</Box>
            </Grid>
            <Grid item xs={11}>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.25em'
                    }}>
                    <Typography variant='body2' sx={typoPrimary}>{col.primary}</Typography>
                    <Typography variant='body2' sx={typoSecondary}>{col.secondary}</Typography>
                </Box>
            </Grid>
        </Grid>
    )
    return (
        <Box sx={wrapperStyles}>
            {renderedResult}
        </Box>
    )
}

export default FooterAboutInfo;