import React, { useState } from 'react';
import { Card, Container, CardContent, CardMedia, Link, Grid, Typography, Paper, Box } from '@mui/material';
import AppLayout from '../../Components/Layout/AppLayout';
import { styled } from '@mui/system';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Team from '../../Components/Team/Team';
import SectionLabel from '../../Components/SectionLabel/SectionLabel';

// team members
import teamOne from '../../assets/team/1.jpg'
import teamTwo from '../../assets/team/2.jpg'
import teamThree from '../../assets/team/3.jpg'
import teamFour from '../../assets/team/4.jpg'
// illustration
import ilOne from '../../assets/about/il-2.jpg'
import ilTwo from '../../assets/about/il-1.jpg'
import ilThree from '../../assets/about/il-4.jpg'


function AboutUs() {

  const teamInfo = [
    {
      id: 1,
      image: teamOne,
      title: 'Yannick',
      subtitle: 'Directeur',
      description: "Notre directeur est le leader visionnaire qui guide notre entreprise avec dévouement, prévoyance stratégique et engagement envers l’excellence. Ils stimulent l'innovation, favorisent la croissance et défendent notre mission, inspirant notre équipe à dépasser les limites et à faire de notre entreprise un phare de réussite au sein de l'industrie.",
      link1: '#',
      link2: '#',
      style: { backgroundColor: '#fff', padding: '20px', textAlign: 'center' },
      contentHeight: '170px',
    },
    {
      id: 2,
      image: teamTwo,
      title: 'Marcus',
      subtitle: "Représentant d'affaires au Gabon",
      description: "Rencontrez notre représentant commercial au Gabon ! La personne incontournable pour tout ce qui concerne la vente. Doté d'un talent pour comprendre vos besoins, il excelle dans la présentation de nos produits/services, dans l'établissement de relations et dans la recherche de solutions sur mesure pour vous. La passion réside dans la fourniture d'un service exceptionnel et dans l'assurance de votre satisfaction tout au long de votre parcours avec nous.",
      link1: '#',
      link2: '#',
      style: { backgroundColor: '#fff', padding: '20px', textAlign: 'center' },
      contentHeight: '170px',
    },
    {
      id: 3,
      image: teamThree,
      title: 'Elijah',
      subtitle: 'Responsable marketing et informatique',
      description: "Rencontrez notre extraordinaire responsable marketing et informatique ! La personne incontournable pour tout ce qui concerne la vente. Doté d'un talent pour comprendre vos besoins, il excelle dans la présentation de nos produits/services, dans l'établissement de relations et dans la recherche de solutions sur mesure pour vous. La passion réside dans la fourniture d'un service exceptionnel et dans l'assurance de votre satisfaction tout au long de votre parcours avec nous.",
      link1: '#',
      link2: '#',
      style: { backgroundColor: '#fff', padding: '20px', textAlign: 'center' },
      contentHeight: '170px',
    },
    {
      id: 4,
      image: teamFour,
      title: 'George',
      subtitle: 'Responsable Douane et Logistique au Gabon',
      description: "Rencontrez notre extraordinaire responsable des douanes et de la logistique ! La personne incontournable pour tout ce qui concerne la vente. Doté d'un talent pour comprendre vos besoins, il excelle dans la présentation de nos produits/services, dans l'établissement de relations et dans la recherche de solutions sur mesure pour vous. La passion réside dans la fourniture d'un service exceptionnel et dans l'assurance de votre satisfaction tout au long de votre parcours avec nous.",
      link1: '#',
      link2: '#',
      style: { backgroundColor: '#fff', padding: '20px', textAlign: 'center' },
      contentHeight: '170px',
    }
  ];
  const useStyles = styled((theme) => ({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    image: {
      width: '100%',
      height: 'auto',
      borderRadius: theme.spacing(1),
    },
    textoverlay: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      color: 'white',
    },
  }));

  const classes = useStyles();

  return (
    <AppLayout>
      <Grid container spacing={4} style={{ padding: '20px' }}>
        {/* Single container for both left and right content */}
        <Grid item xs={12}>
          <Paper style={{ padding: '30px' }}>
            {/* Left side with text and image */}
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                < Typography variant='body1'>
                  <h3>L'HISTOIRE DES ODI MOTORS</h3>
                </ Typography>
                <Typography variant="body1">
                  Qui sommes nous?
                  ODI Motors a été créée par un jeune entrepreneur gabonais en 2010 dans la ville d'Atlanta aux USA.

                  Notre mission est simple : fournir des pièces automobiles de qualité à des prix abordables dans un délai d'exécution rapide. Quelles que soient les pièces que vous recherchez, nous les trouverons pour vous grâce à notre vaste réseau de grossistes et d'équipementiers.
                </Typography>
                < Typography variant='body1'>
                  <h3>Nos Garanties</h3>
                </ Typography>
                < Typography variant='body1'>
                  Les véhicules neufs sont assemblés par les constructeurs automobiles à partir de pièces provenant des équipementiers. Grâce à son réseau mondial, ODI Motors a la possibilité de commercialiser ces
                  pièces et accessoires d'origine provenant des plus grands équipementiers qui fournissent les
                  fabricants : Bendix, Duralast, Bosch, Champion, Bilstein, Goodyear, Knecht, Lucas, Magneti-Marelli,
                  K&N, NGK, Sachs, Valeo… Ces pièces sont toutes conformes à la législation et aux normes en vigueur.
                </ Typography>

              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={ilOne}
                  alt="Left Image"
                  style={{
                    width: '100%',
                    height: '350px',
                    borderRadius: '4px',
                    objectFit: 'cover'
                  }}
                />
              </Grid>
            </Grid>

            {/* Right side with text about a motor website or company and another image */}
            <Grid container spacing={4} style={{ marginTop: '50px' }}>
              <Grid item xs={12} md={6}>
                <img
                  src={ilTwo}
                  alt="Right Image"
                  style={{
                    width: '100%',
                    height: '350px',
                    borderRadius: '4px',
                    objectFit: 'cover'
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                < Typography variant='body1'>
                  <h3>Informations utiles</h3>
                </ Typography>
                <Typography variant="body1">
                  < Typography variant='body1'>
                    <h4>Comment passer votre commande ?</h4>
                  </ Typography>
                  <p>Vous pouvez commander 7 jours sur 7, 24 heures sur 24 par téléphone au +1(404)547-6431 (WhatsApp) ou par e-mail à odi.motors@hotmail.com. Tout ce dont nous avons besoin est : le nom de la pièce, la marque, le modèle, l'année du véhicule et le numéro de châssis. Selon l'origine du véhicule, une photo de la pièce et le numéro de pièce peuvent également être requis.</p>
                  < Typography variant='body1'>
                    <h4>Comment régler votre commande ?</h4>
                  </ Typography>
                  <p>Après avoir accepté le devis qui vous a été fourni, vous pouvez déposer les fonds sur le compte BGFI de notre ODI Motors (notre RIB vous sera fourni) et nous procéderons ensuite à la commande.</p>
                  < Typography variant='body1'>
                    <h4>Comment recevoir votre commande ?</h4>
                  </ Typography>
                  <p>ODI Motors fonctionne uniquement avec UPS. Après expédition de votre colis, un numéro de suivi vous sera fourni. Vous pouvez suivre votre colis directement sur notre site internet en cliquant sur « Suivre votre colis ». Le délai d'expédition est généralement de 10 à 15 jours. À l'arrivée de votre colis au Gabon, un de nos représentants vous contactera pour demander le lieu de livraison.</p>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Team bgColor={'#F5F5F5'} teamInfo={teamInfo} sectionLabel={<SectionLabel label={'Notre '} span={'équipe'} />} />
    </AppLayout>
  );
};

export default AboutUs;
