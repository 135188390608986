import { Box } from "@mui/material";

function FooterSocialMedia({ socialMedia, mt }) {
    const rendredSocialMedia = socialMedia.map((media, index) => <a key={index} style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '36px',
        width: '36px',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: media.bg,
    }} href={media.link}>
        {media.icon}
    </a>)

    return (
        <Box mt={mt} sx={{
            flexGrow: 1,
            display: 'flex',
            gap: '6px',
            alignItems: 'center'
            }}>
            {rendredSocialMedia}
        </Box>
    );
}

export default FooterSocialMedia