import { Typography, Box } from "@mui/material"
import { grey } from "@mui/material/colors"

function SectionLabel({ label, span, align, labelColor }) {
    return (
        <Typography
            mb={2} variant='h4'
            textAlign={align ? align : 'center'}
            fontWeight={300}
            letterSpacing={'.5px'}
            sx={{
                color: labelColor ? labelColor : 'black',
                fontFamily: 'Chivo Mono'
            }}>
            {label}<Box component={'span'} sx={{ color: grey[500] }}>{span}</Box>
        </Typography>
    )
}

export default SectionLabel