import { Container, Toolbar, Box } from "@mui/material";

function AppContainer({ children }) {
    return (
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1 }}>{children}</Box>
            </Toolbar>
        </Container>
    );
}

export default AppContainer