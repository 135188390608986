import Typography from '@mui/material/Typography';
import { grey, yellow } from '@mui/material/colors';
import { Box } from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import PrimaryButton from '../Button/PrimaryButton';

function AboutCard({ label, tag, content }) {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography color={grey['500']} variant={'overline'} gutterBottom>
                {label}
            </Typography>
            <Typography variant="h5" component="div" color={'white'} fontWeight={200} mb={3} gutterBottom sx={{
                fontFamily: 'Chivo Mono',
                color: yellow['800'],
                width: { xs: '100%', md: '90%' }
            }}>
                {tag}
            </Typography>
            <Typography variant="body2" color={grey['100']} fontWeight={100} mb={4} gutterBottom sx={{
                letterSpacing: '1px',
                lineHeight: 2,
                width: { xs: '100%', md: '90%' }
            }}>
                {content}
            </Typography>
            <PrimaryButton btnText={'En savoir plus'} url={'/about'} size={'large'} icon={<ArrowCircleRight sx={{ color: 'black' }} />} />
        </Box>
    )
}

export default AboutCard