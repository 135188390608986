import { Box, Typography } from "@mui/material"
import PrimaryButton from "../Button/PrimaryButton"
import { ArrowCircleRight } from "@mui/icons-material"
import './HeroVideoCarousel.css'

function HeroVideoCarousel({ src, title, description }) {
    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'black'
        }}>
            <video autoPlay muted loop style={{
                width: '100%',
                height: '90vh',
                objectFit: 'cover',
                opacity: 0.35
            }}>
                <source src={src} type="video/mp4" />
            </video>
            <Box sx={{
                flexGrow: 1,
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                <Typography variant='h4' color={'white'} mb={1} sx={{
                    fontFamily: 'Chivo Mono',
                    fontWeight: 600,
                    width: '70%'
                }}>{title}</Typography>
                <Typography variant='body1' color={'white'} mb={2} sx={{
                    letterSpacing: '1px',
                    lineHeight: 2
                }}>{description}</Typography>
                <PrimaryButton btnText={'Demander un devis'} url={'/contact'} size={'large'} icon={<ArrowCircleRight sx={{ color: 'black' }} />} />
            </Box>
        </Box>
    )
}

export default HeroVideoCarousel