import React from "react";
import styled from "styled-components";
import { Paper } from "@mui/material";
import AppLayout from "../../Components/Layout/AppLayout";
import Tracking from "./Tracking";

const CenteredPaper = styled(Paper)(() => ({
  textAlign: "center",
  width: "100%",
  boxSizing: "border-box",
  margin: "60px auto",
  maxWidth: "800px",
  padding: "50px",
}));


const TrackingPage = () => {
  return (
    <>
      <AppLayout>
        <CenteredPaper style={{ backgroundColor: "whitesmoke" }}>
          <Tracking />
        </CenteredPaper>
      </AppLayout>
    </>
  );
};

export default TrackingPage;
