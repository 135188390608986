import React, { useEffect } from "react";
import { Box, Container, Grid } from '@mui/material';
import AppLayout from "../../Components/Layout/AppLayout";
import AppContainer from "../../Components/AppContainer/AppContainer";
import { grey, yellow } from "@mui/material/colors";
import HeroVideoCarousel from "../../Components/Carousel/HeroVideoCarousel";
import SectionLabel from "../../Components/SectionLabel/SectionLabel";
import About from "../../Components/About/About";
import Faqs from "../../Components/Faq/Faqs";
// services
import sCustom from "../../assets/service/custom.jpg";
import sShip from "../../assets/service/shipping.jpg";
import sQnp from "../../assets/service/q-n-p.jpg";
// about
import aboutImage from "../../assets/about/1.jpg"
// partners
import pat1 from "../../assets/partner/1.png";
import pat2 from "../../assets/partner/2.png";
import pat3 from "../../assets/partner/3.png";
import pat4 from "../../assets/partner/4.png";
import pat5 from "../../assets/partner/5.png";
import pat6 from "../../assets/partner/6.png";
import pat7 from "../../assets/partner/7.png";
import pat8 from "../../assets/partner/8.png";
import pat9 from "../../assets/partner/9.png";
import pat10 from "../../assets/partner/10.png";
import pat11 from "../../assets/partner/11.png";
import pat12 from "../../assets/partner/12.png";
import pat13 from "../../assets/partner/13.png";
import pat14 from "../../assets/partner/14.png";
import pat15 from "../../assets/partner/15.png";
import pat16 from "../../assets/partner/16.png";
import pat17 from "../../assets/partner/17.png";
import pat18 from "../../assets/partner/18.png";
import pat19 from "../../assets/partner/19.png";
import pat20 from "../../assets/partner/20.png";
import pat21 from "../../assets/partner/21.png";
import pat22 from "../../assets/partner/22.png";
import pat23 from "../../assets/partner/23.png";
import pat24 from "../../assets/partner/24.png";
import pat25 from "../../assets/partner/25.png";
import pat26 from "../../assets/partner/26.png";
import pat27 from "../../assets/partner/27.png";
import {
    AddHome,
    AirplanemodeActive,
    ShoppingCart,
} from "@mui/icons-material";
import ServicesCard from "../../Components/Card/ServicesCard";
import Tracker from "../../Components/Tracker/Tracker";
import ReviewCard from "../../Components/Card/ReviewCard";
import Slider from "react-slick";

const aboutInfo = {
    label: "Qui sommes nous?",
    image: aboutImage,
    tag: "..ODI Motors a été créée par un jeune entrepreneur gabonais en 2010 dans la ville d'Atlanta aux USA.",
    content: "Notre mission est simple : fournir des pièces automobiles de qualité à des prix abordables dans un délai d'exécution rapide. Quelles que soient les pièces que vous recherchez, nous les trouverons pour vous grâce à notre vaste réseau de grossistes et d'équipementiers.",
};

const faqs = [
    {
        title: "Comment passer votre commande?",
        content: "Vous pouvez commander 7 jours sur 7, 24 heures sur 24 par téléphone au +1(404)547-6431 (WhatsApp) ou par e-mail à odi.motors@hotmail.com. Tout ce dont nous avons besoin est : le nom de la pièce, la marque, le modèle, l'année du véhicule et le numéro de châssis. Selon l'origine du véhicule, une photo de la pièce et le numéro de pièce peuvent également être requis.",
    },
    {
        title: "Comment régler votre commande ?",
        content: "Après avoir accepté le devis qui vous a été fourni, vous pouvez déposer les fonds sur le compte BGFI de notre ODI Motors (notre RIB vous sera fourni) et nous procéderons ensuite à la commande.",
    },
    {
        title: "Comment recevoir votre commande ?",
        content: "ODI Motors fonctionne uniquement avec UPS. Après expédition de votre colis, un numéro de suivi vous sera fourni. Vous pouvez suivre votre colis directement sur notre site internet en cliquant sur « Suivre votre colis ». Le délai d'expédition est généralement de 10 à 15 jours. À l'arrivée de votre colis au Gabon, un de nos représentants vous contactera pour demander le lieu de livraison.",
    },
]

const partners = [
    pat1,
    pat2,
    pat3,
    pat4,
    pat5,
    pat6,
    pat7,
    pat8,
    pat9,
    pat10,
    pat11,
    pat12,
    pat13,
    pat14,
    pat15,
    pat16,
    pat17,
    pat18,
    pat19,
    pat20,
    pat21,
    pat22,
    pat23,
    pat24,
    pat25,
    pat26,
    pat27,
];

function HomePage() {
    const renderedPartners = partners.map((partner, index) => (
        <img key={index} src={partner} />
    ))

    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 7500,
        autoplaySpeed: 7500,
        cssEase: "linear",
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
    };

    useEffect(() => {
        const onScrollAnimation = () => {
            let elements = document.querySelectorAll('#main-anim');
            let windowHeight = window.innerHeight;
            let elementVisible = 80;

            for (let i = 0; i < elements.length; i++) {
                let elementTop = elements[i].getBoundingClientRect().top;

                if (elementTop < windowHeight - elementVisible) {
                    if (i % 2 == 0) {
                        elements[i].classList.add("slide-in-left");
                    }
                    else {
                        elements[i].classList.add("slide-in-right");
                    }
                } else {
                    if (i % 2 == 0) {
                        elements[i].classList.remove("slide-in-left");
                    }
                    else {
                        elements[i].classList.remove("slide-in-right");
                    }
                }
            }
        }
        window.addEventListener('scroll', onScrollAnimation);
        return () => { window.removeEventListener('scroll', onScrollAnimation) }
    }, [])

    return (
        <AppLayout>
            {/* Hero Section */}
            <HeroVideoCarousel
                src={
                    "/videos/odimotors.mp4"
                }
                title={"Votre partenaire pour l'achat de vos pieces auto"}
                description={"Fournisseur de pièces détachées"}
            />
            {/* Services */}
            <div id="main-anim">
                <Box sx={{ flexGrow: 1 }} py={6} bgcolor={grey["100"]}>
                    <AppContainer>
                        <div id="title-anim">
                            <SectionLabel label={"Nos"} span={" Services"} />
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <ServicesCard
                                    img={sQnp}
                                    name={"Devis & Achat"}
                                    icon={
                                        <ShoppingCart
                                            sx={{ fontSize: "4em", color: yellow["900"] }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ServicesCard
                                    img={sShip}
                                    name={"Emballage et expédition express"}
                                    icon={
                                        <AirplanemodeActive
                                            sx={{ fontSize: "4em", color: yellow["900"] }}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <ServicesCard
                                    img={sCustom}
                                    name={"Dédouanement et livraison à domicile"}
                                    icon={
                                        <AddHome sx={{ fontSize: "4em", color: yellow["900"] }} />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AppContainer>
                </Box>
            </div>
            {/* About section */}
            <div id="main-anim">
                <Box
                    py={12}
                    bgcolor={"black"}
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <AppContainer>
                        <About aboutInfo={aboutInfo} />
                    </AppContainer>
                </Box>
            </div>
            {/* Partners Section */}
            <div id="main-anim" className="marquee-container" style={{
                borderBottom: `2.4px solid ${grey['300']}`,
                backgroundColor: 'white'
            }}>
                <div className="marquee">{renderedPartners}</div>
            </div>
            {/* FAQs Section */}
            <div id="main-anim">
                <Box pt={8} pb={8} bgcolor={grey["200"]} sx={{ flexGrow: 1 }}>
                    <AppContainer>
                        <div id="title-anim">
                            <SectionLabel label={"Informations"} span={" utiles"} />
                        </div>
                        <Faqs faqs={faqs} />
                    </AppContainer>
                </Box>
            </div>
            <div id="main-anim">
                <Box flexGrow={1} overflow={'hidden'} py={6} bgcolor={grey['200']}>
                    <SectionLabel label={'Les'} span={' clients disent..'} />
                    <Slider {...settings} style={{ margin: '40px 0' }}>
                        <div>
                            <ReviewCard name={"Yann Livulibutt Yangari"} comment={"Fast and secure great service"} date={"June 6 2022"}/>
                        </div>
                        <div>
                        <ReviewCard name={"Cheval Doré"} comment={"Merci pour votre promptitude ! J'ai bien reçu mes pièces et"} date={"November 26 2021"}/>
                        </div>
                        <div>
                        <ReviewCard name={"Rachel Redon-Bord"} comment={"J ai commandé un rétroviseur pour une Elantra GT ...J ai cherché 3 jours sur libreville rien à faire . ODI motors m' a fait un devis en  15mn j ai reçu ma pièce 15 jours après 5 étoiles"} date={"7 january 2017"}/>
                        </div>
                        <div>
                        <ReviewCard name={"Sekou Cissé Bakary"} comment={"Odi Motors, enterprise tres sérieuse je vous la recommande vivement 👌 Rapide et fiable ✔"} date={"March 10th 2022"}/>
                        </div>
                    </Slider>
                </Box>
            </div>
            {/* tracker */}
            <div id="main-anim">
                <Tracker bgColor={grey['300']} />
            </div>

        </AppLayout>
    );
}

export default HomePage;
