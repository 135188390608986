import { Box } from "@mui/material";
import AppFooter from "../Footer/AppFooter";
import AppHeader from "../Header/AppHeader";

function AppLayout({ children }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppHeader />
      {children}
      <AppFooter />
    </Box>
  );
}

export default AppLayout;
