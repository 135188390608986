import React from 'react';
import { Card, CardContent, CardMedia, Link, Grid, Typography, Paper, Box } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { grey } from '@mui/material/colors';
import AppContainer from '../AppContainer/AppContainer';

function Team({ bgColor, teamInfo, sectionLabel }) {
    return (
        <Paper style={{ paddingTop: '40px', paddingBottom: '80px', backgroundColor: bgColor }}>
            {sectionLabel}
            <AppContainer>
                <Grid container spacing={2} justifyContent={'center'}>
                    {teamInfo.map((card) => (
                        <Grid item xs={12} sm={6} md={3} key={card.id}>
                            <Card style={card.style}>
                                {/* Rounded image */}
                                <CardMedia
                                    component="img"
                                    alt={`Rounded Image ${card.id}`}
                                    height="180"
                                    image={card.image}
                                    style={{
                                        borderRadius: '999px',
                                        width: '180px',
                                        margin: 'auto',
                                    }}
                                />
                                <CardContent style={{ height: card.contentHeight }}>
                                    {/* Text underneath the image */}
                                    <Typography variant="subtitle1" fontWeight={700} component="div" sx={{
                                        fontFamily: 'Chivo Mono',
                                        letterSpacing: '1px'
                                    }}>
                                        {card.title}
                                    </Typography>
                                    <Typography variant="subtitle2" color={grey['500']} sx={{ letterSpacing: '1px', fontFamily: 'Chivo Mono' }}>
                                        {card.subtitle}
                                    </Typography>
                                    {/* <Typography variant="caption" color={grey['700']} component={'div'} my={4} sx={{ letterSpacing: '1px' }}>
                                        {card.description}
                                    </Typography> */}
                                    {/* Links underneath the text */}
                                    <div className="links">
                                        <Link href="#" variant="caption" style={{ fontSize: '5px', color: grey['400'], marginRight: '10px' }}>
                                            <FacebookOutlinedIcon />
                                        </Link>
                                        <Link href="#" variant="caption" style={{ fontSize: '5px', color: grey['400'], marginRight: '10px' }}>
                                            <TwitterIcon />
                                        </Link>
                                        <Link href="#" variant="caption" style={{ fontSize: '5px', color: grey['400'], marginRight: '10px' }}>
                                            <YouTubeIcon />
                                        </Link>
                                        <Link href="#" variant="caption" style={{ fontSize: '5px', color: grey['400'], marginRight: '10px' }}>
                                            <InstagramIcon />
                                        </Link>
                                    </div>
                                    <br />
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </AppContainer>
        </Paper>
    )
}

export default Team