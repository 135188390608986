import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Grid, Box, TextField, Button, Paper } from "@mui/material";
import AppLayout from "../../Components/Layout/AppLayout";
import { tracking, authClient } from "../../api/ups";
import Notification from "../../Components/Alert/AlertDialog";
import { GpsFixed } from "@mui/icons-material";
import TrackingTable from "../../Components/Table/TrackingTable";

const Headings = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Heading = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #000;
`;

const ProgressTracking = styled.div`
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 20px 0; /* Adjusted margin */
  position: relative;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: orange;
  border-radius: 5px;
`;

const Terminal = styled.div`
  position: absolute;
  top: -10px;
  width: 30px;
  height: 30px;
  background-color: orange;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

const TerminalIcon = styled(CheckCircleIcon)`
  font-size: 20px;
`;

const LastTerminal = styled(Terminal)`
  background-color: orange;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12%;
`;

const IconLabel = styled.p`
  font-size: 12px;
  color: #212121;
  margin-top: 15px;
`;

const Tracking = () => {
    const [trackingNumber, setTrackingNumber] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState("");
    const [showTracking, setShowTracking] = useState(false);
    const [data, setData] = useState({});
    const [shpData, setShpData] = useState({});
    const [progress, setProgress] = useState(0);
    const [shpstatus, setShpstatus] = useState("");

    const Tracking = async () => {
        setShowTracking(false)
        if (trackingNumber === "") {
            setAlertMessage("Le numéro de suivi ne peut pas être vide !!");
            setOpen(true);
            setAlertType("error");
        } else {
            closeNotification();
            setAlertMessage("La demande est en cours de traitement !!");
            setOpen(true);
            setAlertType("info");

            const resp = await tracking(trackingNumber);
            
            closeNotification();
            if (resp?.response?.trackResponse) {
                setData(resp?.response);
                setShpData(resp?.response?.trackResponse?.shipment[0]?.package[0]?.packageAddress)
                setShowTracking(true);
            }

            if (resp?.response?.response?.errors[0]?.message) {
                setAlertMessage(resp?.response?.response?.errors[0]?.message);
                setOpen(true);
                setAlertType("error");
            }
        }
    };

    function closeNotification() {
        setAlertMessage("");
        setOpen(false);
        setAlertType("");
    }

    return (
        <>
            <Box flexGrow={1}>
                <Typography variant="caption" color="GrayText">
                    SAISISSEZ VOTRE IDENTIFIANT DE SUIVI POUR SUIVRE VOS ARTICLES
                </Typography>
                <Box mt={4} sx={{ flexGrow: 5 }}>
                    <TextField
                        onChange={(e) => setTrackingNumber(e.target.value)}
                        fullWidth
                        label="Votre identifiant de suivi.."
                        variant="filled"
                        color="primary"
                        focused
                    />
                    <Button
                        onClick={Tracking}
                        fullWidth
                        variant="contained"
                        color="warning"
                        sx={{ marginTop: "8px" }}
                        size="large"
                        startIcon={<GpsFixed />}
                    >
                        Suivre votre colis
                    </Button>
                </Box>
            </Box>

            {showTracking && data && (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                marginY: 3,
                                width: '100%',
                            },
                        }}
                    >
                        <Paper elevation={0} sx={{ textAlign: 'left', p: 2 }}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                                Les détails d'expédition
                            </Typography>
                            <p>Statut actuel: {data?.trackResponse?.shipment[0]?.package[0]?.currentStatus?.simplifiedTextDescription}</p>
                            <p>Depuis: {shpData[0]?.address?.city + ", " + shpData[0]?.address?.country}</p>
                            <p>À: {shpData[1]?.address?.city + ", " + shpData[1]?.address?.country}</p>
                        </Paper>
                    </Box>
                    <div style={{ marginTop: 20 }}>
                        <TrackingTable data={data} />
                    </div>
                </>
            )}

            {open && (
                <Notification
                    open={open}
                    message={alertMsg}
                    severity={alertType}
                    horizontal={"right"}
                    handleClose={closeNotification}
                />
            )}
        </>
    );
};

export default Tracking;
