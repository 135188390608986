import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import { ArrowCircleRight } from '@mui/icons-material';
import { grey, yellow } from '@mui/material/colors';

function FaqItem({ title, content }) {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography fontWeight={500} sx={{letterSpacing: '1px', display: 'flex', gap: '10px', alignItems: 'center'}}><ArrowCircleRight sx={{color: yellow['900']}} /> {title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography fontWeight={300} sx={{letterSpacing: '1px'}}>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    )
}

export default FaqItem