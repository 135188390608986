import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, TextField, Button } from '@mui/material';
import { GpsFixed } from '@mui/icons-material';
import { grey, yellow } from '@mui/material/colors';
import AppContainer from '../AppContainer/AppContainer';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SectionLabel from '../SectionLabel/SectionLabel';
import Tracking from '../../Pages/TrackingPage/Tracking';

const steps = [
    'Commande traitée',
    'Commande expédiée',
    'Commande en cours de route',
    'Commande en attente d\'arrivée'
];

const walkThrough = [
    {
        label: 'Commande traitée',
        description: `C'est le moment où vous avez saisi les exigences de la commande et cliqué sur le bouton de commande.`,
    },
    {
        label: 'Commande expédiée',
        description: 'Découvrez si vos marchandises ont pris la route.',
    },
    {
        label: 'Commande en cours de route',
        description: `Vos marchandises sont-elles toujours en chemin?.`,
    },
    {
        label: 'Commande en attente d\'arrivée',
        description: `Ne paniquez pas. Asseyez-vous et détendez-vous, nous vous livrerons bientôt !.`,
    }
];


function Tracker({ bgColor }) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const TrackerBackup = <Box mt={4} sx={{ flexGrow: 1 }}>
        <TextField fullWidth label="Order Number.." variant="filled" color="primary" focused />
        <Button fullWidth variant="contained" startIcon={<GpsFixed />} sx={{
            color: 'black',
            fontWeight: '300',
            marginTop: '6px',
            backgroundColor: yellow['900'],
            '&:hover': {
                backgroundColor: yellow['800']
            }
        }} size={'large'}>
            Track your package
        </Button>
    </Box>

    return (
        <Box bgcolor={bgColor} py={8} sx={{ flexGrow: 1 }}>
            <AppContainer>
                <Grid container spacing={6} alignItems={'center'}>
                    <Grid item xs={12} md={6}>
                        <Box mt={4} sx={{ flexGrow: 1 }}>
                            <Tracking />
                        </Box>
                        {/* <Box flexGrow={1} mt={4}>
                            <Stepper activeStep={1} alternativeLabel>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Box> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SectionLabel label={"Let's"} span={" Walkthrough"} align={'left'} />
                        <Box sx={{ maxWidth: 400 }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {walkThrough.map((step, index) => (
                                    <Step key={step.label}>
                                        <StepLabel
                                            optional={
                                                index === 2 ? (
                                                    <Typography variant="caption">Last step</Typography>
                                                ) : null
                                            }
                                        >
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <Typography>{step.description}</Typography>
                                            <Box sx={{ mb: 2 }}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                    </Button>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={handleBack}
                                                        sx={{ mt: 1, mr: 1 }}
                                                    >
                                                        Back
                                                    </Button>
                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>All steps completed - you&apos;re finished</Typography>
                                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                        Reset
                                    </Button>
                                </Paper>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </AppContainer>
        </Box>
    )
}

export default Tracker