import React from "react";
import { useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import AppLayout from "../../Components/Layout/AppLayout";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SupportIcon from "@mui/icons-material/Support";
import MailIcon from "@mui/icons-material/Mail";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import { yellow } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import PhoneInTalk from "@mui/icons-material/PhoneInTalk";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";

function ContactForm() {
  const primaryColor = yellow[800];

  const theme = createTheme({
    palette: {
      primary: {
        main: "#nnn", // Set your custom primary color
      },
      secondary: {
        main: primaryColor, // Set your custom secondary color
      },
    },
  });

  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const containerStyle = {
    display: isExtraSmallScreen ? "block" : "flex",
    justifyContent: isExtraSmallScreen ? "initial" : "space-between",
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "white" ? "white" : "white",
    ...theme.typography.body2,
    padding: theme.spacing(4),
    textAlign: "start",
    color: theme.palette.text.secondary,
  }));

  const StyledTextField = styled(TextField)(
    ({ theme, customWidth, customHeight, customBorderColor }) => ({
      "& .MuiInputBase-root": {
        width: customWidth || "auto",
        height: customHeight || "auto",
        border: `2px solid ${customBorderColor || theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
      },
    })
  );

  const CustomizableTextField = ({ width, height, ...props }) => (
    <StyledTextField
      customWidth={width}
      customHeight={height}
      // customBorderColor={borderColor}
      {...props}
    />
  );

  const buttonStyle = {
    marginTop: "20px",
    backgroundColor: primaryColor,
    color: "black",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const inputData = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const [loader, setLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [validationErrors, setValidationErrors] = useState(null);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setValidationErrors(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    try {
      const response = await fetch(
        `https://advanztektesting.tech/api/contact/store`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(inputData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.success) {
        handleModalOpen();
        setLoader(false);

        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
      }
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing JSON:", error.message);
      } else {
        console.error("Error details:", error);
        console.error("Error message:", error.message);
      }
      setLoader(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <Paper style={{ padding: "30px", backgroundColor: "#F5F5F5" }}>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            Contact
          </Typography>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 5 }}
          >
            <Grid xs={12} md={4}>
              <Item>
                <h3 className="Contacts-heading" style={{ color: "black" }}>
                  Prenez contact
                </h3>
                <p className="contacts-text">
                  ODI MOTORS Fournisseur de pièces de rechange Vos pièces d'auto
                  de qualité à des prix imbattables
                </p>
                <h3 className="Contacts-heading">
                  ODIMOTORS LLC , 424 SUMMER DRIVE ATLANTA GEORGIA 30328 USA
                </h3>
                <div style={{ paddingTop: "10px" }}>
                  <p className="contacts-info">
                    <AddIcCallIcon style={{ paddingRight: "10px" }} />
                    TÉL : +241 07-69-60-70
                  </p>
                  <p className="contacts-info">
                    <PlaceIcon style={{ paddingRight: "10px" }} />
                    BP 13847 LIBREVILLE GABON
                  </p>
                  <p className="contacts-info">
                    <PhoneInTalk style={{ paddingRight: "10px" }} />
                    TÉL : +1 (404) 547-6431 ÉTATS-UNIS (WhatsApp)
                  </p>
                  <p className="contacts-info">
                    <PlaceIcon style={{ paddingRight: "10px" }} />
                    424 SUMMER DRIVE ATLANTA GEORGIA 30328 USA
                  </p>
                  <p className="contacts-info">
                    <SupportIcon style={{ paddingRight: "10px" }} />
                    support@odimotors.com
                  </p>
                  <p className="contacts-info">
                    <MailIcon style={{ paddingRight: "10px" }} />
                    odi.motors@hotmail.com
                  </p>
                </div>
                <div className="contacts-icon" style={{ paddingTop: "15px" }}>
                  <p>
                    <a href="https://www.facebook.com/odimotors">
                      <FacebookOutlinedIcon />
                    </a>
                    <a href="#">
                      <TwitterIcon />
                    </a>
                    <a href="#">
                      <YouTubeIcon />
                    </a>
                    <a href="#">
                      <GoogleIcon />
                    </a>
                    <a href="https://instagram.com/odimotors?igshid=OGQ5ZDc2ODk2ZA==">
                      <InstagramIcon />
                    </a>
                  </p>
                </div>
              </Item>
            </Grid>
            <Grid xs={12} md={8}>
              <Item>
                <h3 style={{ color: "black" }}>Envoyez-nous un message :</h3>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      Votre Nom<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="name"
                      onChange={(event) =>
                        (inputData.name = event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption">
                      Votre Email<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      type="email"
                      onChange={(event) =>
                        (inputData.email = event.target.value)
                      }
                    />
                  </Grid>
                </Grid>
                <div>
                  <Typography
                    variant="caption"
                    style={{ marginTop: "10px", display: "block" }}
                  >
                    Sujet<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    fullWidth
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    name="subject"
                    onChange={(event) =>
                      (inputData.subject = event.target.value)
                    }
                  />
                </div>
                <div>
                  <Typography
                    variant="caption"
                    style={{ marginTop: "10px", display: "block" }}
                  >
                    Votre message<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="message"
                    onChange={(event) =>
                      (inputData.message = event.target.value)
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={buttonStyle}
                    variant="standard"
                    onClick={handleSubmit}
                  >
                    <Typography variant="caption">Soumettre</Typography>
                  </Button>
                  {loader && (
                    <div style={{ marginLeft: "10px", marginTop: "25px" }}>
                      <CircularProgress size={29} />
                    </div>
                  )}
                </div>
              </Item>
            </Grid>
          </Grid>

          <Dialog open={openModal} onClose={handleModalClose}>
            {validationErrors ? (
              <>
                <DialogTitle>Error in Form Submission</DialogTitle>
                <DialogContent>
                  <Typography style={{ color: "red" }}>
                    Please adjust your data to fix the following errors:
                  </Typography>
                  <ul>
                    {Object.values(validationErrors).map((error, index) => (
                      <li key={index}>{error[0]}</li>
                    ))}
                  </ul>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleModalClose}>Close</Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle>Message Sent Successfully!</DialogTitle>
                <DialogContent>
                  <Typography>
                    Thank you for contacting us. We will get back to you soon.
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleModalClose}>Close</Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Paper>
      </AppLayout>
    </ThemeProvider>
  );
}

export default ContactForm;
