import {
  EmailOutlined,
  FacebookRounded,
  LinkedIn,
  LocalPhone,
  LocationOn,
  Twitter,
  WhatsApp,
  Send,
  MarkEmailRead,
  LocalPhoneTwoTone,
  LocationOnRounded,
  LocationSearching,
  PhoneEnabled,
  DirectionsCarFilled,
  Pinterest,
} from "@mui/icons-material";
import {
  Box,
  Grid,
  Typography,
  Stack,
  Link,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { yellow, grey, blue, green, red } from "@mui/material/colors";
import FooterAboutInfo from "./FooterAboutInfo";
import AppContainer from "../AppContainer/AppContainer";
import FooterSocialMedia from "./FooterSocialMedia";

const footerAboutInfoIconStyles = { color: grey[700], fontSize: "1em" };
const socialInfoIconStyles = { color: "white" };

const footerAboutInfo = [
  {
    icon: <LocationOn sx={footerAboutInfoIconStyles} />,
    primary: `424 Summer Drive Atlanta, GA 30328 USA`,
    secondary: "",
  },
  {
    icon: <LocalPhone sx={footerAboutInfoIconStyles} />,
    primary: "+1(404) 547-6431",
    secondary: "7dys - Week, 24hrs - Day",
  },
  {
    icon: <LocationSearching sx={footerAboutInfoIconStyles} />,
    primary: `BP 13847 Libreville Gabon`,
    secondary: "",
  },
  {
    icon: <PhoneEnabled sx={footerAboutInfoIconStyles} />,
    primary: "+241 07-69-60-70",
    secondary: "7dys - Week, 24hrs - Day",
  },
  {
    icon: <EmailOutlined sx={footerAboutInfoIconStyles} />,
    primary: "odi.motors@hotmail.com",
    secondary: "",
  },
];

const socialMediaInfo = [
  {
    icon: <FacebookRounded sx={socialInfoIconStyles} />,
    bg: blue[800],
    link: "https://www.facebook.com/odimotors",
  },
  {
    icon: <Twitter sx={socialInfoIconStyles} />,
    bg: blue[300],
    link: "https://twitter.com",
  },
  {
    icon: <WhatsApp sx={socialInfoIconStyles} />,
    bg: green["500"],
    link: "https://twitter.com",
  },
  {
    icon: <LinkedIn sx={socialInfoIconStyles} />,
    bg: blue["A700"],
    link: "https://instagram.com/odimotors?igshid=OGQ5ZDc2ODk2ZA==",
  },
  {
    icon: <Pinterest sx={socialInfoIconStyles} />,
    bg: red["A700"],
    link: "https://facebook.com",
  },
];

const pages = [
  { label: "Accueil", url: "/" },
  { label: "À propos", url: "/about" },
  { label: "Demander un devis", url: "/contact" },
  { label: "Suivre votre colis", url: "/track" },
];

function AppFooter() {
  return (
    <Box
      pt={5}
      pb={2}
      sx={{
        flexGrow: 1,
        backgroundColor: "black",
      }}
    >
      <AppContainer>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}>
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontFamily: "Chivo Mono",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
              fontWeight={400}
              mb={3}
            >
              <DirectionsCarFilled
                sx={{
                  display: { xs: "none", md: "flex" },
                  mr: 1,
                  color: yellow["900"],
                }}
              />
              <Box flexGrow={1}>
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    fontWeight: 400,
                    // letterSpacing: '.2rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    fontFamily: 'Chivo Mono'
                  }}
                >
                  ODI<Box component={'span'} sx={{ color: 'white', marginLeft: 1 }}> MOTORS</Box>
                </Typography>
              </Box>
            </Typography>

            <FooterAboutInfo footerAboutInfo={footerAboutInfo} />

            <Box mt={5} sx={{ flexGrow: 1 }}>
              <Typography variant={"overline"} color={"GrayText"}>
                REJOIGNEZ-NOUS:
              </Typography>
              <FooterSocialMedia socialMedia={socialMediaInfo} mt={1} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Typography variant={"overline"} color={"GrayText"}>
              NAVIGATION RAPIDE
            </Typography>
            <Box mt={3} sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                {pages.map((page, index) => (
                  <Link
                    key={index}
                    href={page.url}
                    variant={"caption"}
                    underline={"hover"}
                    color={"Menu"}
                  >
                    {page.label}
                  </Link>
                ))}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography variant={"subtitle1"} color={"white"}>
              ABONNEZ-VOUS POUR LES DERNIÈRES MISES À JOUR
            </Typography>
            <Typography variant={"caption"} color={"GrayText"}>
              Abonnez-vous à notre Newsletter et obtenez des bonus pour le
              prochain achat
            </Typography>
            <Box mt={4} sx={{ flexGrow: 1 }}>
              <TextField
                sx={{
                  borderColor: "yellow",
                  borderWidth: 2,
                  color: "white",
                  "& input": {
                    color: "white",
                    borderColor: "yellow", // set the border color for the input
                    borderWidth: 2, // set the border width for the input
                  },
                }}
                fullWidth
                label="Entrez votre e-mail.."
                variant="filled"
                color="primary"
                focused
                InputProps={{
                  style: {
                    borderColor: "yellow", // set the border color for the TextField
                    borderWidth: 2, // set the border width for the TextField
                  },
                }}
              />
              <Button
                fullWidth
                variant="contained"
                endIcon={<MarkEmailRead />}
                sx={{
                  color: "black",
                  fontWeight: "300",
                  marginTop: "6px",
                  backgroundColor: yellow["900"],
                  "&:hover": {
                    backgroundColor: yellow["800"],
                  },
                }}
                size={"large"}
              >
                Envoyer
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Divider
          sx={{
            bgcolor: grey["500"],
            marginTop: 4,
            marginBottom: 2,
          }}
        />

        <Box sx={{ flexGrow: 1 }}>
          <Typography
            variant="caption"
            align="center"
            sx={{ color: grey[400], letterSpacing: "1px" }}
          >
            Copyright © 2023 OdiMotors. Propulsé par Advanztek
          </Typography>
        </Box>
      </AppContainer>
    </Box>
  );
}

export default AppFooter;
