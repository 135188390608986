import React, { useEffect } from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { AddHome, DirectionsCarFilled, GpsFixed, Message, PeopleAltRounded, ShoppingCartOutlined } from '@mui/icons-material';
import { yellow } from '@mui/material/colors';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import PrimaryButton from '../Button/PrimaryButton';

const headerTheme = createTheme({
  palette: {
    primary: {
      // main: grey['900'],
      main: '#000',
    },
  },
  typography: {
    fontFamily: 'Onest, sans-serif',
  },
  overrides: {
    MuiAppBar: {
      root: {
        fontFamily: 'Onest, sans-serif',
      },
    },
  },
});

const pages = [
  { label: 'Menu', url: '/', icon: <AddHome sx={{ color: yellow['900'] }} /> },
  { label: 'À propos', url: '/about', icon: <PeopleAltRounded sx={{ color: yellow['900'] }} /> },
  { label: 'Demander un devis', url: '/contact', icon: <ShoppingCartOutlined sx={{ color: yellow['900'] }} /> },
  { label: 'Suivre votre colis', url: '/track', icon: <GpsFixed sx={{ color: yellow['900'] }} /> },
  { label: 'Contact', url: '/message', icon: <Message sx={{ color: yellow['900'] }} /> }
];

function AppHeader() {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    const stickyAppBar = () => {
      const appBar = document.getElementById('app-bar-nav')

      if (window.scrollY > appBar.offsetTop) {
        appBar.classList.add('sticky-app-bar')
      } else {
        appBar.classList.remove('sticky-app-bar')
      }
    }
    window.addEventListener('scroll', stickyAppBar)
    return () => window.removeEventListener('scroll', stickyAppBar)
  }, [])

  return (
    <ThemeProvider theme={headerTheme}>
      <div id="app-bar-nav">
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <DirectionsCarFilled sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, color: yellow['900'] }} />
              <Typography
                variant="h5"
                noWrap
                component="a"
                href="/"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  fontWeight: 400,
                  // letterSpacing: '.2rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  fontFamily: 'Chivo Mono'
                }}
              >
                ODI<Box component={'span'} sx={{ color: 'white', marginLeft: 1 }}> MOTORS</Box>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleDrawer}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  sx={{
                    display: {
                      xs: {
                        display: 'block',
                        // position: 'fixed',
                        // bottom: 0,
                        // left: 0,
                        // width: '100%'
                      },
                      md: 'none'
                    },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      boxSizing: 'border-box',
                    },
                  }}
                  variant="persistent"
                  anchor='bottom'
                  open={drawerOpen}
                >
                  <List>
                    {pages.map((page, index) => (
                      <ListItem key={index} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            {page.icon}
                          </ListItemIcon>
                          <Link to={page.url}><ListItemText primary={page.label} /></Link>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </Box>
              <DirectionsCarFilled sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 1,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontWeight: 400,
                  letterSpacing: '1px',
                  color: 'inherit',
                  textDecoration: 'none',
                  fontFamily: 'Chivo Mono',
                }}
              >
                ODI<Box component={'span'} sx={{ color: 'white' }}> MOTORS</Box>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                {pages.map((page, index) => (
                  <Button
                    key={index}
                    startIcon={page.icon}
                    sx={{ display: 'flex', justifyContent: 'center', textTransform: 'capitalize' }}
                  >
                    <Link to={page.url}><Typography variant='button' fontWeight={300} color={'white'} sx={{ letterSpacing: '.8px', fontFamily: 'Chivo Mono' }}>{page.label}</Typography></Link>
                  </Button>
                ))}
              </Box>

              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
                <PrimaryButton btnText={'Demander un devis'} url={'/contact'} icon={<ShoppingCartOutlined sx={{ color: 'black' }} />} />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </ThemeProvider>
  );
}
export default AppHeader;