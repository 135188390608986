import { Grid } from '@mui/material';
import FaqItem from './FaqItem';

function Faqs({ faqs }) {
    const renderedFaqs = faqs.map((faq, index) => <FaqItem key={index} title={faq.title} content={faq.content} />)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}></Grid>
            <Grid item xs={12} sm={12} md={8}>
                {renderedFaqs}
            </Grid>
            <Grid item xs={12} sm={12} md={2}></Grid>
        </Grid>
    )
}

export default Faqs