import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const columns = [
    { id: 'date', label: 'Date', minWidth: 170 },
    { id: 'time', label: 'Temps', minWidth: 100 },
    { id: 'status', label: 'Statut', minWidth: 100 },
    { id: 'location', label: 'Emplacement', minWidth: 100 },
];

function TrackingTable(data) {
    const rows = data?.data?.trackResponse?.shipment[0]?.package[0]?.activity

    function dateFormatter(dateStr) {
        const parsedDate = new Date(
            `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6, 8)}`
        );

        const formattedDate = parsedDate.toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });

        return formattedDate;
    }

    function timeFormatter(timeStr) {
        const formattedTime = `${timeStr.slice(0, 2)}:${timeStr.slice(2, 4)}:${timeStr.slice(4, 6)}`;
        return formattedTime;
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, key) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                    {columns.map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {
                                                    column.id === "status" ?
                                                        value.description
                                                        : column.id === "location" ?
                                                            value?.address?.city + ", " + value?.address?.country
                                                            : column.id === "date" ?
                                                                dateFormatter(value)
                                                                : column.id === "time" ?
                                                                    timeFormatter(value)
                                                                    : null
                                                }
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default TrackingTable;
