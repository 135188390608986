import { Button } from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { Link } from 'react-router-dom';

function PrimaryButton({ btnText, icon, size, url }) {
    return (
        <Link to={url ? url : '/'}>
            <Button variant="contained" sx={{
                color: 'black',
                fontWeight: '300',
                fontFamily: 'Chivo Mono',
                backgroundColor: yellow[900],
                letterSpacing: '1px',
                '&:hover': {
                    backgroundColor: yellow[800]
                }
            }} startIcon={icon} size={size ? size : 'medium'}>
                {btnText}
            </Button>
        </Link>
    )
}

export default PrimaryButton