import { Grid } from '@mui/material';
import AboutCard from '../Card/AboutCard'

function About({ aboutInfo }) {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
                <img
                    style={{
                        width: '100%',
                        height: '350px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                        // boxShadow: '6px 6px 8px rgba(30, 30, 30, .75)'
                    }}
                    src={aboutInfo.image}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <AboutCard label={aboutInfo.label} tag={aboutInfo.tag} content={aboutInfo.content} />
            </Grid>
        </Grid>
    )
}

export default About