import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, yellow } from '@mui/material/colors';

const themeFont = createTheme({
  typography: {
    fontFamily: 'Onest, sans-serif',
  },
  palette: {
    primary: {
      main: yellow['900'],
    },
    secondary: {
      main: grey[900],
    }
  }
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={themeFont}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
)
